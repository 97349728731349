/********* GNERALITIES ******/

:root {
    --box-sizing: border-box;
    --bg-btnMap: #d9d9d9;
    --bg-btnMapHover: #c5c1c1;
    --color-btnMapFocus: #ffffff;
    --bg-btnMap-BFocus: #0096c7;
    --bg-btnMap-SFocus: #52b788;
    --bg-btnMap-HFocus: #c1121f;
    --color-markerHover: #0000FF;



}

* {
    box-sizing: border-box;
}

body {
    color: #323;
}





/********* PAGE ASSOCIATION ******/
.ContainerAssocaition {
    height: 100vh;
    margin: 2rem;
    transition: transform 3s ease-in-out
}



/* section btn map */
.sectionBtnMap {
    margin-bottom: 2.2rem;
    text-align: center;
}

.btnMap {
    all: unset;
    cursor: pointer;
    width: 15rem;
    height: 3rem;
    background-color: var(--bg-btnMap);
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: .2rem;


}

.btnMap:hover {
    background-color: var(--bg-btnMapHover);
}

.btnMap:focus,
.btnMap.active {
    color: var(--color-btnMapFocus);
    background: #323;
}

#btnMap-B:focus,
#btnMap-B.active {
    background: var(--bg-btnMap-BFocus);
}


#btnMap-S:focus,
#btnMap-S.active {
    background: var(--bg-btnMap-SFocus);
}

#btnMap-H:focus,
#btnMap-H.active {
    background: var(--bg-btnMap-HFocus);
}

/* map */

.popup {
    display: flex;
    flex-direction: column;
}

.popup span {
    font-weight: bolder;
    color: var(--color-markerHover);
    margin-bottom: .5rem;

}

.ContainerAssocaition .popup a {
    text-decoration: none;
    color: #323;
}

.imgStructure {
    width: 100%;
    margin-top: 1rem;
    min-width: 15rem;

}

.sLegendMap {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 4rem;
    left: 2rem;
    z-index: 400;
    width: 10rem;
    height: 7rem;
    background-color: rgba(255, 255, 255, 0.447);
    font-weight: bolder;
    border-radius: .2rem;
}



.sLegendMap div {
    display: flex;
    margin: .9rem;

}

.sLegendMap img {
    margin-right: .5rem;

}







/* 
.leaflet-container {
    background-color: #ADD8E6 !important;
    background-color: #83A697 !important;
    background: none;
} */
/* .leaflet-container {
    background-color: white !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
} */

/* .leaflet-control-zoom-in {
    padding-top: 5rem !important;
} */

/* .leaflet-control-zoom {
    margin-top: 3rem !important;
}



 .leaflet-control .leaflet-control-zoom {
    margin-top: 3rem !important;
}

/* .leaflet-control-zoom leaflet-bar leaflet-control */